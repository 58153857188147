import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import '../styles/contact.css'
const Contact = () => (
    <Layout>
        <Seo title="Contact" />
        <div id="school-image2"></div>
        <div className="contact-page">

            <h1>We would love to hear from <span id="accent">you!</span></h1>
            <div className="contact-boxes">
                <div className="contact-box2">
                    <h2>USA Contact:</h2>
                    <p>
                        Scott & Adriane Kim Hosking <br />
                        <br />
                        Call or Text <br />
                        435-851-4989<br />

                        <br />
                        62 East 200 South <br />
                        Ephraim, Utah 84627 <br />
                    </p>

                </div>
                <div className="contact-box2">
                  <h2>  Guatemala Contact</h2>
                    <p>
                        Nixon Lima <br/>
                        <br/>
                    Teléfono: <br/>
                    (502) 5524-6675<br/>
                    <br/>
                    Lote 254-A, Barrio El Centro,<br/>
                    16013 Chisec, Alta Verapaz, Guatemala<br/>

                    </p>
                </div>
            </div>
        </div>
    </Layout>
)

export default Contact
